import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../../components/layout'
import HeaderGeneric from '../../components/HeaderGeneric'

class Generic extends React.Component {
  render() {

    return (
      <Layout>
        <Helmet title="श्री गुरूगजाननलीला ग्रंथ - अध्याय १९ वा" />
        <HeaderGeneric title="श्री गुरूगजाननलीला ग्रंथ" adhyaay='अध्याय १९ वा' num='19'/>
        <div id="main">
          <section id="content" className="main">
            <p>
            श्री गणेशाय नम: । हे ईशा परमेश्वरा । निराकारातून येणार्‍या । साकारातून परत जाणार्‍या ॥ १ ॥
हे नृसिंहा श्रीकृष्णा । श्रीरामा गौरीरमणा । आपण यावे माझ्या स्थाना । सरस्वतीचा मनोरथ पूर्ण करण्या ॥ २ ॥
भोवतालचे वातावरण । धुंद करते माझे मन । कैसे होईल ग्रंथ लेखन ? । कैसा होईल त्यात रममाण ? ॥ ३ ॥
नको गुंतवू त्यात । मी गुंतता त्यात । पूर्ण न होणार ग्रंथ । भंग होईल मनोरथ ॥ ४ ॥
हे ईशा परमेश्वरा । आनंद टिकविणार्‍या । धावून यावे सत्वरा । कृपा करावी ह्या लेकरा ॥ ५ ॥
करतो पुन्हा पुन्हा विनवणी । चालू राहू दे लेखणी । तुझ्या कृपेवाचूनी । न चाले लेखणी ॥ ६ ॥
तूच माझे ऐकतो । जेणे तुला विनवितो । पुन्हा पुन्हा आमंत्रितो । विश्वास तुझ्यावर ठेवतो ॥ ७ ॥
तू भेटतो वेगळ्या रूपात । भक्तांसही भेटावे त्या रूपात । विश्वास ठेवतील माझ्यात । अन्यथा कुणी न रहाणार विश्वासात ॥ ८ ॥
जेणे होईल भक्तीचा र्‍हास । तूच कारण तुझ्या र्‍हासास । तूच तुझ्या नाशास । मी न कारण दोषास ॥ ९ ॥
हे सर्व जाणणार्‍या । निराकारा परमेश्वरा । घात करू नको बरा । हा हेतू जाण जरा ॥ १० ॥
आपल्या मनातले बेत । परमेश्वर जाणतो तंतोतंत । तसेच भक्तांच्या जे जे मनात । ते ते समर्थ जाणतात ॥ ११ ॥
काशीनाथ गर्दे एक भक्त । जे जे हेतू त्याच्या मनात । ते ते महाराज जाणत । वेळ प्रसंगी साध्य करत ॥ १२ ॥
खामगावचा गर्दे काशीनाथ । जाणत होता महाराज योगी निश्चित । योग लीला अनुभवण्यात । सदा आला विचार मनात ॥ १३ ॥
महाराजांच्या दर्शनास आला । नोकरीचा विचार मनातला । अंतर्ज्ञाने समर्थे जाणला । पटवले काशिनाथाला ॥ १४ ॥
समर्थ कोपरखळी मारतात । तेणे त्यास सुचवितात । पोस्टमन तुझ्या दारात । तुझे टपाल त्याच्या हातात ॥ १५ ॥
तुझ्या बदलीचे वृत्त । आहे त्या टपालीत । झाला आश्चर्यचकित । कैसे जाणतात समर्थ ? ॥ १६ ॥
नतनम्र झाला । आशिष मागता झाला । प्रसाद घेऊन निघाला । घरी येऊन पोहोचला ॥ १७ ॥
तो तेच दृष्य पहाता झाला । तारवाला तार घेऊन आला । तारेतला मजकूर वाचला । मुनसफीच्या हुद्द्यावर बदली झालेला ॥ १८ ॥
आनंदाने काही सुचेना । समर्थांवाचून काही दिसेना । योगी म्हटल्यावाचून रहावेना । वाटे पुन्हा पुन्हा जावे दर्शना ॥ १९ ॥
पुन्हा शेगाव आठवले । महाराज आठवले । मनोमनी वंदन केले । शेगावीच आहोत असे वाटले ॥ २० ॥
जसे आपणास कामानिमित्ये । बाहेर जावे लागते । तसे महाराज जात होते । भक्तमनोरथ पुरवत होते ॥ २१॥
येथे फरक मोठा जाणवतो । आपण आपल्यासाठी जगतो । आपलीच तुंबडी भरतो । योगी दुसर्‍यासाठी जगतो ॥ २२ ॥
जावे लागते कित्येक गावांस । असेच एकदा नागपुरास । गोपाळबुटीच्या घरास । गेले आशिष देण्यास ॥ २३ ॥
गोपाळबुटीचा भव्य वाडा । वैभव संपत्ती घाले सडा । लक्ष्मीनारायण देखिल वेडा । त्याने पसंद केला वाडा ॥ २४ ॥
सर्व गुरुकृपेने मिळते । गुरूकृपेनेच टिकते । आपले काहीच नसते । हे सत्य माहित होते ॥ २५ ॥
महाराज गेले त्याच्या घरी । गान भजन कीर्तन वरचेवरी । ज्ञानाच्या वर्षावाच्या सरी । नादाच्या उमटत होत्या लहरी ॥ २६ ॥
होती देवघेव ज्ञानाची । हिम्मत नसे कुणाची । ते थांबविण्याची । लक्षणे होती भाग्याची ॥ २७ ॥
जो तो होता आनंदात । सुखदुःख विस्मरणात । यथेच्छ होत होता तृप्त । आनंदास होता लुटत ॥ २८ ॥
एक एक घरी नेत होता । जमवत होता । कुणी अडवत नव्हता । जो तो स्वतंत्र होता ॥ २९ ॥
ऐसी होती कर्मगती । जी सहज चालली होती । भोजनाच्या त्या पंक्ती । एका मागुनी एक त्या उठती ॥ ३० ॥
अन्नदान पुण्य त्या बुटीस । आले त्याच्या भाग्यास । खोचत होता कनवटीस । कृतकृत्य होत होता मनास ॥ ३१ ॥
जो एक एक क्षण यायचा । तो तेथेच थांबायचा । तेथून हलण्याचा । त्याचा विचार नसायचा ॥ ३२ ॥
पुण्यक्षण वाढत होते । कमी होत नव्हते । सत्वृत्तीचे होते भरते । सत्कर्माशी जडले नाते ॥ ३३ ॥
गोपाळबुटीच्या घरचे । काय पुसावे त्यांचे । म्हणती थोर भाग्य आमचे । हेच म्हणायचे ॥ ३४ ॥
होते एकाच्या भाग्यात । दुसर्‍याचे भाग्य मिसळत । आनंदास द्विगुणित करत । सारे सहज होते घडत ॥ ३५ ॥
परंतु ह्या सर्वास । विविक्षित मर्यादा त्यास । माहित होती विधात्यास । नव्हती गोपाळ बुटीस ॥ ३६ ॥
विधाता नसतो एकट्याचा । तो असतो सर्वांचा । हेच सर्वांना पटवण्याचा । हेतू होता त्यांच्या मनाचा ॥ ३७ ॥
योग्यवेळेस सारे घडते । मुद्दाम करायचे नसते । योगायोगाने होते । ठरलेल्या वेळी अचूक होते ॥ ३८ ॥
नागपूरच्या गोपाळ बुटीस । आनंद जितका मनास । तितका हरी पाटलास । शेगावी गुरू विरह त्रास ॥ ३९ ॥
होता विरहाने तडफडत । जेणे त्याचे मन अस्वस्थ । लक्ष नव्हते व्यवसायात । नव्हते निद्रा भोजनात ॥ ४० ॥
जसे लेकराची माता । परगावी जाता । जी स्थिती होते चित्ता । ती तो झाला अनुभवता ॥ ४१ ॥
हरी मातेच्या भेटीला । झाला अगदी उतावळा । तो न थांबू शकला । अगदी अधीर झाला ॥ ४२ ॥
मनाचा तोल गेला । स्वतःशीच बोलता झाला । आत्ताच जावे नागपुराला । गोपाळ बुटीच्या वाड्याला ॥ ४३ ॥
विचारात दिवस गेला । परी एक दिवस उजाडला । तो शेवटी नागपुरला गेला । महाराजांना आणायला ॥ ४४ ॥
हे जाणले महाराजांनी । अंतर्ज्ञानानी । गोपाळास बोलावुनी । सांगितले तत्क्षणी ॥ ४५ ॥
गोपाळ समजला मनास । विरह दुःख झाले त्यास । महाराज सोडतिल आपणास । आता मुकू आनंदास ॥ ४६ ॥
महाराजांना नाही म्हणवेना । गुरू चरण सोडेना । गुरू चरणाविना । होईन मी सुनासुना ॥ ४७ ॥
पंगत चालू असता । हरी पाटिल झाला येता । केव्हा पहातो समर्थां ? । असे झाले त्याच्या चित्ता ॥ ४८ ॥
महाराजे प्रसंग ओळखला । बुटीस समजावला । बळी नको आग्रहाला । हरी आला मला घ्यायला ॥ ४९ ॥
इकडे भोजन पंगत । विचार चक्रे बुटीच्या मनात । अर्धे लक्ष पंगतीत । अर्धे लक्ष पुढच्या विचारात ॥ ५० ॥
त्याने विनविले हरीस । सर्व बसले भोजनास । नेऊ नये महाराजांस । ऐशा समयास ॥ ५१ ॥
बुटीने हरी पाटिलास । केला आग्रह भोजनास । म्हणे बसावे भोजनास । मग जावे शेगावास ॥ ५२ ॥
आत्ताच तुम्ही घेऊन जाता । गोंधळ माजेल तत्वता । ब्राह्मण उपाशी उठता । पाप येईल माझ्या वाट्या ॥ ५३ ॥
बोलणे पटले हरीस । संमती दिली भोजनास । होऊ दिले भोजनास । आनंद झाला गोपाळास ॥ ५४ ॥
गोपाळाने म्हणावे । एक एक क्षण जाऊ नये । ब्राह्मणांनी भोजनात गुंतावे । तेवढेच पुण्य खोचण्यास मिळावे ॥ ५५ ॥
पुण्याईला हपापलेला फार । गुरूस न सोडण्यास तयार । भोजन उरकल्यावर । महाराज निघाले जाण्यास तयार ॥ ५६ ॥
गोपाळाची वाणी मौन राहिली । डोळ्यात आसवे आली । पती पत्नी चरणी पडली । म्हणे ईच्छा अपूर्णच राहिली ॥ ५७ ॥
तिच्या हेतूस ओळखले । भाळी कुंकू लावले । तिला आशिर्वाद दिले । पुढे तिला पुत्ररत्न झाले ॥ ५८ ॥
महाराज सांगती भक्तांस । ऐसे करावे भक्तीस । पात्र ठरावे आशिर्वादास । पात्र ठरावे गुरूकृपेस ॥ ५९ ॥
परत जाताना शेगावास । दिले दर्शन अनेकांस । गेले सीताबर्डीस । गेले रामटेकास ॥ ६० ॥
महाराज आले शेगावी । भक्तगण त्यांची वाट पाही । जो तो दर्शनास येई । कृपाशिष घेऊन जाई ॥ ६१ ॥
एकदा महाराजे बाळाभाऊस । अंतर्ज्ञानाने सांगितले त्यास । शेगावच्या मठास । येत आहेत दर्शनास ॥ ६२ ॥
वासुदेवानंद सरस्वती । वसती माणगावाप्रती । भेटण्यास उत्सुक अती । उद्या येणार निश्चिती ॥ ६३ ॥
तसेच धारचे रंगनाथ । उत्सुक भेटण्यास । येणार शेगावात । उद्या निश्चित ॥ ६४ ॥
दुसरे दिवशी तसेच घडले । दोघेही तेथे आले । सांकेतिक बोलणे झाले । बाळाभाऊस आश्चर्य वाटले ॥ ६५ ॥
समर्थ वदती बाळाभाऊस । कर्म, भक्ती, योगाने गेल्यास । जाल ज्ञानमार्गास । तेणे ज्ञान मिळेल तुम्हांस ॥ ६६ ॥
वसिष्ठ, वामदेव, जमदग्नी । अत्री, पराशर, शांडिल्य मुनी । कर्ममार्ग गेले आचरुनी । गेले भवसागर तरुनी ॥ ६७ ॥
व्यास, नारद, कयाधुकुमार । शबरी, मारूती, अक्रूर । भक्तीमार्गात तत्पर । गेले तरून भवसागर ॥ ६८ ॥
मच्छींदर, गोरख, जालंदर । योगमार्गात तत्पर । जरी योगमार्ग कठिण फार । परी त्यानेच तरती भवसागर ॥ ६९ ॥
समर्थांना भक्त फार । काहिंना जवळ करणार । काहिंना दूर लोटणार । पूर्वसंचितानुसार घडणार ॥ ७० ॥
एके दिवशी साळूस । महाराज म्हणती तीस । तू न सोडावे मठास । रहावे स्वयंपाकास ॥ ७१ ॥
पटला विचार साळूबाईस । मानले जीने गुरूआज्ञेस । राहिली तेथे मठास । जेवू घाली सर्वांस ॥ ७२ ॥
जलंब गावच्या तुळशीरामास । आत्माराम पुत्र त्यास । ह्यास जावे लागले काशीस । वेदपठण करण्यास ॥ ७३ ॥
आत्माराम झाला वेद पारंगत । घरी आला परत । यावयाचा शेगावात । बसायचा समर्थ सान्निध्यात ॥ ७४ ॥
वेद म्हणून दाखवे समर्थांस । जेथे जेथे चूक म्हणण्यात । समर्थे आणावी निदर्शनास । आश्चर्य वाटले आत्मारामास ॥ ७५ ॥
चुका प्रसंगानुसार सुधरवायचे । हेच समर्थांचे चालायचे । भक्त वारंवार यायचे । आपली चूक जाणायचे ॥ ७६ ॥
भक्तांना जागे करण्यात । गुरू तत्पर असतात । तेच महाराज होते करत । हेच भक्त अनुभवत ॥ ७७ ॥
असाच प्रसंग एका भक्ताचा । बाळापुर तालुक्याचा । मोरगावच्या मारुतीपंताचा । जोंधळ्याचे शेत रक्षण्याचा ॥ ७८ ॥
मोरगावचा मारुतीपंत । होता शेतीवाडीयुक्त । धार्मिक प्रवृत्ती युक्त । होता सदा धर्मरत ॥ ७९ ॥
शेताची राखण करण्यास । ठेवले नोकरीस । तिमाजी नोकरास । स्वस्थता अनुभवण्यास ॥ ८० ॥
तिमाजी शेत रक्षीतसे । नोकरीत चोख असे । कधी फसवत नसे । इमाने इतबारे वागत असे ॥ ८१ ॥
नोकर कितीही कार्यदक्ष । चूक होण्यात रागाचे भक्ष्य । असाच प्रसंग तिमाजीस । होता त्याचे दुर्लक्ष ॥ ८२ ॥
शेताची राखण करत असता । बरीच रात्र उलटून जाता । डुलकीस झाला देता । विपरीत प्रसंग झाला भोगता ॥ ८३ ॥
पहाता पहाता डुलकी लागली । शेतात गाढवे घुसली । जोंधळ्याची रास खाऊ लागली । शेताची नासधूस झाली ॥ ८४ ॥
समर्थे अंतर्ज्ञाने जाणली । परी स्वस्थ न राहिली । ही गुरूमाऊली । तत्क्षणी तिथे आली ॥ ८५ ॥
तिमाजीस हाक मारती झाली । तिमाजीस म्हणाली गुरूमाऊली । “अरे! शेतात गाढवे शिरली” । तिमाजीस जाग आली ॥ ८६ ॥
आपली चूक लक्षात आली । ही डुलकी भारी पडली । क्षणभरात गोंधळ करती झाली । त्यास हळहळ वाटली ॥ ८७ ॥
झोपेने गोंधळ केला । आपला घात केला । करुणा भाकता झाला । म्हणे ह्यातून वाचवा मला ॥ ८८ ॥
नंतर तो जागाच राहिला । उजाडता मालकाकडे गेला । वृत्तांत सर्व सांगितला । मारूतीस फार राग आला ॥ ८९ ॥
वाटेल कोणत्या मालकाला । नुकसान व्हावे आपणाला । नुकसान नको मालकाला । मारूतीपंत त्याच विचारातला ॥ ९० ॥
परी होता गुरूसेवेत । उत्सुक दर्शन करण्यात । आधी सकाळी जाई मठात । मगच जाई शेतात ॥ ९१ ॥
हाच क्रम चालायचा । काटेकोर पाळायचा । बदल न व्हावयाचा । जरी प्रसंग नफातोट्याचा ॥ ९२ ॥
गुरूसेवा न फुकट जाणार । प्रसंग कितीही बिकट । जाणत होता मारूतीपंत । तेणे होता तैसा वागत ॥ ९३ ॥
जरी नुकसान झाले त्याला । तरी तो तिमाजीस म्हणाला । आधी जातो मठाला । मग येतो शेताला ॥ ९४ ॥
आधी गेला मठास । महाराजांचे दर्शनास । केला नमस्कार त्यांस । म्हणतो कृपा असावी आम्हांस ॥ ९५ ॥
महाराज वदती “मारुतीपंता! । झोपाळू नोकर ठेवता । गाढवे गोंधळ करता । आमची झोप मोडता” ॥ ९६ ॥
गुरूंना झालेला त्रास । सहन न झाला त्यास । तो वदे समर्थांस । नोकरीतुन काढतो नोकरास ॥ ९७ ॥
महाराज म्हणती त्यावर । अरे! तिमाजी इमानी नोकर । दुःख झाले अनावर । जणू प्रसंग स्वतःवर ॥ ९८ ॥
उजाडताच तो भेटला । तू त्यावर म्हणाला । “आधी जातो मठाला । मग येतो शेताला” ॥ ९९ ॥
वृत्तांत सारा पटला । मारुतीपंत थक्क झाला । समर्थांच्या अंतर्ज्ञानाला । मारुतीपंत अनुभवता झाला ॥ १०० ॥
प्रमाण मानले गुरू आज्ञेस । नोकरीत ठेवले तिमाजीस । गुरू रक्षिती भक्तांस । अनुभव आला दोघांस ॥ १०१ ॥
महाराजांचे असेच असायचे । कधी स्थिर नसायचे । वाटेल तेथे बसायचे । काळवेळ विसरायचे ॥ १०२ ॥
असेच एकदा हमरस्त्यावर । सुखलाल बन्सीलालच्या दुकानासमोर । नग्नावस्थेत रस्त्यावर । प्रसंग ओढवला समर्थांवर ॥ १०३ ॥
नारायण आसराजी हवालदार । त्याने पाहिला प्रकार । एक नंगा ढोंगी रस्त्यावर । जो तो करी त्यास नमस्कार ॥ १०४ ॥
हे न खपले त्याला । तो दरडावू लागला । महाराजांस हाकलवू लागला । त्यांना दंडे मारू लागला ॥ १०५ ॥
बोलण्याचा परिणाम होईना । महाराज जागचे हालत ना । अनेक प्रकारे समजावले त्यांना । परी हुं का चुं बोलेना ॥ १०६ ॥
पहाता पहाता गर्दी झाली । महाराजांना पाहू लागली । महाराजांची कीव आली । हवालदाराकडे पाहू लागली ॥ १०७ ॥
हवालदारे दरडावले । चालते व्हा येथून झाले । असे म्हणता दंडे उगारले । काहिंना झोडपले ॥ १०८ ॥
त्यातला एक पुढे आला । त्यास समजावू लागला । नका मारू सांगू लागला । त्यास बाजूस करू लागला ॥ १०९ ॥
परी जागे झाले शिपाईपण । म्हणे “आणखीन बडवीन । झोडपून काढीन । उगाच नंगा फिरतो गावातुन” ॥ ११० ॥
तेवढ्यात हुंडीवाल्या दुकानदारास । न पाहवले दृष्यास । त्याने आवरले शिपायास । दूर केले सर्व लोकांस ॥ १११ ॥
तो महाराजांजवळ गेला । त्यांचा हात धरला । म्हणे “चला माझ्या दुकानाला । पाणी देतो तुम्हाला” ॥ ११२ ॥
इकडे शिपायास समजावले । माफी मागण्यास सांगितले । शिपायाने नाकारले । हेच पुढच्यास कारण झाले ॥ ११३ ॥
महाराजांना मारल्यामुळे । काळाने त्याला घेरले । ईहलोकातुन उठवले । पुढे सारे घरच उध्वस्त झाले ॥ ११४ ॥
एका सत्पुरुषास । विनाकारण मारल्यास । कारण होते दुष्परीणामास । ऐसेच झाले हवालदारास ॥ ११५ ॥
महाराज समर्थ होते । लोकांस पटत होते । नतमस्तक होत होते । कृपाशिर्वाद घेत होते ॥ ११६ ॥
जो तो संधी सोडत नव्हता । आपापल्यापरीने येत होता । त्यांच्याजवळ मागत होता । लोटांगण घालत होता ॥ ११७ ॥
संगमनेर, नगर जिल्ह्यातले । भक्त यायचे तेथले । नाना हेतू मनातले । साध्य व्हायचे दर्शनामुळे ॥ ११८ ॥
हरी जाखडा तेथला । तोही ऐकता झाला । समर्थ एक शेगावला । तो ही दर्शना उत्सुक झाला ॥ ११९ ॥
हरी जाखडा भीक मागतसे । कधी उपासमार होत असे । जीवनाला कंटाळत असे । जीवाला चैन नसे ॥ १२० ॥
कुठच्या जीवास । आवडे त्रास व्हावेत आपणास । चिमटे बसावे पोटास । जो तो उत्सुक टाळण्या त्रास ॥ १२१ ॥
हरी जाखडाही त्यातला । आशाळभूत शेगावी आला । समर्थ चरणी पडला । ढसा ढसा रडु लागला ॥ १२२ ॥
त्यांचे चरण सोडेना । आशिर्वादावाचून निघेना । समर्थे जाणले त्याच्या मना । म्हणती पूर्ण होवोत मनोकामना ॥ १२३ ॥
समर्थ हरीस म्हणे । का रे झाले येथे येणे ? । हरी त्यावर म्हणे । आलो संसारसुखाकारणे ॥ १२४ ॥
उदर भरतो भीक मागून । कधी असतो उपाशी राहून । मी दरिद्री नारायण । सुंदर कन्या कोण करील अर्पण ? ॥ १२५ ॥
धन धान्य संपत्ती । स्त्रीसुख संतती । मागितले महाराजांप्रती । महाराज पूर्ण करोत ती ॥ १२६ ॥
महाराज थुंकले त्यावर । त्याच्या अज्ञानावर । त्यास आणण्या भानावर । समर्थ वदले त्यावर ॥ १२७ ॥
जो तो विनवितो सुटण्यासाठी । नच गुरफटण्यासाठी । तू आलास गुरफटण्यासाठी । सहाय्यभूत होवो जगत्‌जेठी ॥ १२८ ॥
महाराजांजवळ कुणी मागे भोग । कुणी सांगे बरा करा रोग । कुणी मागे विद्यायोग । जैसा ज्याचा योग ॥ १२९ ॥
निमोणकर होता त्यातला । योगविद्या मागता झाला । त्याचीच तळमळ असलेला । तेच प्रार्थिता झाला ॥ १३० ॥
रामचंद्र निमोणकर । होता ओव्हरसीयर । संगे वासुदेव बेंद्रे सर्व्हेयर । आले मुकना नदीवर ॥ १३१ ॥
सह्याद्री पर्वतात । मुकना नदी डोंगरात । नाशिक जिल्ह्यात । इगतपुरी तालुक्यात ॥ १३२ ॥
आले एका पर्वणीस । तीर्थस्नान करण्यास । गर्दी जमे नाल्यास । कपिलधारा प्रवाहास ॥ १३३ ॥
रामचंद्र निमोणकरास । येत होता योगाभ्यास । तो होण्या पूर्णत्वास । पुसे तेथल्या साधूस ॥ १३४ ॥
परी कुणी न दिसे योग्यतेचा । तेणे योग शिकण्याचा । विचार तसाच रहायचा । शेवटी हताश व्हायचा ॥ १३५ ॥
स्नान आटोपल्यावर । उठला निमोणकर । बसला काठावर । तो एक साधू दृष्टीगोचर ॥ १३६ ॥
पाहिले त्या साधूस । आजानुबाहूस । बसलेला समाधीस । पाहिले आशेच्या किरणास ॥ १३७ ॥
त्यांची समाधी काही उतरेना । उत्तर काही मिळेना । हेतू सफळ होईना । परी तेथून निघवेना ॥ १३८ ॥
असाच एक दिवस सरला । निमोणकर उपाशी राहिला । चित्ती ध्यास लागलेला । कैसे करावे योगाभ्यासाला ॥ १३९ ॥
साधू चतुर होता । परिक्षा घेत होता । तिष्ठत ठेवत होता । परत जातो का पहात होता ॥ १४० ॥
निमोणकर चिकाटीचा होता । तो कंटाळलेला नव्हता । तो गरजवंत होता । वाटेल ते करण्यास तयार होता ॥ १४१ ॥
सूर्य अस्तास गेला । साधू समाधीतून उतरला । निमोणकरास बघता झाला । त्याच्यावर पाणी शिंपडू लागला ॥ १४२ ॥
साधूचे पाय धरले । योगाभ्यासाचे विनवले । साधूने स्मितहास्य केले । निमोणकरास उभे केले ॥ १४३ ॥
साधू म्हणे त्यास । फार कठीण योगाभ्यास । जाऊ नको तू वाटेस । न जमणार तुला योगाभ्यास ॥ १४४ ॥
निमोणकराने हट्ट न सोडला । त्यांस पुन्हा विनवता झाला । साधूने त्यास तक्ता दिला । म्हणे पूर्ण करेल मनोरथाला ॥ १४५ ॥
तांबडा खडा प्रसाद दिला । निमोणकर तेथून निघाला । पुढे हाच साधू नाशिकला दिसला । मागील आठवणीस उजाळा दिला ॥ १४६ ॥
साधूस झाला बोलता । तुझे नाव गाव न सांगता । का रे झाला बेपत्ता ? । तूच दिलास ना तक्ता ? ॥ १४७ ॥
साधू म्हणाला निमोणकराला । तू मूर्खच राहिला । तांबडा खडा जो दिला । तो खूण पटवायला ॥ १४८ ॥
माझे नाव गजानन । आणखीन काय पटवू खूण ? । नको शंकेस स्थान । अभ्यास कर श्रद्धा ठेवून ॥ १४९ ॥
दोघे बरोबर निघाले । दोघे वाटेने चालू लागले । क्षणात गजानन गुप्त झाले । निमोणकर मनात गोंधळले ॥ १५० ॥
निमोणकर पुढे चालू लागला । धुमाळ सदनी पोहोचला । तो हाच साधू तेथे दिसला । आश्चर्य वाटले निमोणकराला ॥ १५१ ॥
धुमाळास वृत्तांत सांगितला । धुमाळ मनात समजला । मनोमन खूष झाला । तो निमोणकराला म्हणाला ॥ १५२ ॥
तो तांबडा गणपती । योगाभ्यास शिकवेल तुजप्रती । तो तक्ता नि गणपती । गजानन समज चित्ती ॥ १५३ ॥
असेच होते समर्थांचे भक्त । पूर्ण व्हायचे मनोरथ । तुकाराम कोकाटे त्यात । रहात होता शेगावात ॥ १५४ ॥
तुकारामाची संतती । जगू शकत नव्हती । करू लागला तो भक्ती । म्हणे नांदू दे घरी संतती ॥ १५५ ॥
अशीच भक्ती करू लागला । एक सुदिन उजाडला । समर्थांच्या चरणी लागला । संतती मागता झाला ॥ १५६ ॥
म्हणाला महाराजांस । संतती जगल्यास । थोरला पुत्र आपणास । अर्पण करीन तुम्हांस ॥ १५७ ॥
पुढे मुले झाली दोन तीन । आनंदात झाला तल्लीन । नवसाची न राहिली आठवण । प्रपंची गेला गुंतून ॥ १५८ ॥
प्रपंच मोहाने नवस विसरला । थोरला नारायण आजारी पडला । औषधोपचार सुरू झाला । परी आराम न पडला ॥ १५९ ॥
तो चिंताक्रांत झाला । त्याने भगवंत स्मरला । प्रार्थना केली त्याला । म्हणे आराम पडू दे प्रकृतीला ॥ १६० ॥
कालांतराने आठवले । नवसास केलेले । नवस विसरता दुखणे आले । असे मन सांगू लागले ॥ १६१ ॥
विनवू लागला समर्थांना । समर्थांस केली प्रार्थना । तुम्हीच वाचवा नारायणा । आराम पडूद्या दुखण्या ॥ १६२ ॥
तुकाराम म्हणे सद्गुरूराया । पुत्र नारायण वाचवा । हा पुत्र वाचलीया । येईन अर्पण कराया ॥ १६३ ॥
ऐसे वचनबद्ध होता । नाडी ठिकाणी झाली येता । डोळे उघडून झाला पहाता । पुत्र वाचला मरता मरता ॥ १६४ ॥
व्याधी बरी झाल्यावर । तुकाराम आला मठावर । म्हणे नारायणास वाहतो तव चरणांवर । करा नवसाचा स्विकार ॥ १६५ ॥
समर्थांच्या चरणी जैसा नारायण । तुकारामे केला अर्पण । नवसाचे भान ठेवून । झाला मुक्त नवसातून ॥ १६६ ॥
तैसे स्मरण गजाननास । झाले नवसाचे भान त्यास । देह अर्पण करावा विठ्ठलास । पूर्ण होता कार्यभागास ॥ १६७ ॥
जैसा नारायण आज्ञेत । गजाननाच्या शब्दात । तैसे गजानन आज्ञेत । विठ्ठलाच्या शब्दात ॥ १६८ ॥
आपला नवस फेडण्यास । जाणीव होऊ लागली गजाननास । म्हणे एके दिवशी हरीस । चला जाऊ पंढरपुरास ॥ १६९ ॥
विठ्ठलाच्या दर्शनास । नवस फेडण्यास । कृपाशिष घेण्यास । कृपाप्रसाद घेण्यास ॥ १७० ॥
पुढे शके अठराशे बत्तीस । साधारण नाम संवत्सरास । आषाढमासास । गेले पंढरपुरास ॥ १७१ ॥
महाराज विठ्ठलास वदले । नवस केलेले आठवले । कार्य माझे संपले । नवस फेडणे झाले ॥ १७२ ॥
ह्या वर्षीच्या ऋषीपंचमीस । फेडीन नवसास । स्विकारावे त्यास । लावावे पुढिल कार्यास ॥ १७३ ॥
समर्थां विरह जाणवला । कंठ दाटून आला । अश्रु गाली ओघळला । हरी पहातच राहिला ॥ १७४ ॥
महाराज वदती हरीस । नवस होता विठ्ठलास । तो आलो फेडण्यास । तो पूर्ण करण्यास ॥ १७५ ॥
हरी आता संगत थोडी राहिली । ऋषीपंचमी जवळ आली । जायची वेळ आली । विठ्ठलाची आज्ञा झाली ॥ १७६ ॥
ह्या वर्षीच्या ऋषीपंचमीस । अवतार कार्य संपवीन मी । राहीन तुमच्या अंतर्यामी । स्मरताच दिसेन मी ॥ १७७ ॥
हरी म्हणे महाराजांस । सोडू नका आम्हांस । तुमचाच आधार आम्हांस । निराधार करू नका पोरांस ॥ १७८ ॥
हरीस कळला अर्थ । म्हणे थोडे दिवस समर्थ । विठ्ठला करू नको अनर्थ । दीर्घायुषी होऊ दे समर्थ ॥ १७९ ॥
गोपाळबुटीच्या वाड्यातुन । महाराज आणले हिरावून । तेच प्रारब्ध भोग म्हणुन । न भोग सुटे भोगल्यावाचून ॥ १८० ॥
हरीच्या नयनी अश्रू आले । पुढचे चित्र दिसू लागले । प्रफुल्लित मन मावळले । निराशेचे सूर कानी आले ॥ १८१ ॥
दोघांनी घातल्या प्रदक्षिणा । घेऊ लागले विठ्ठल दर्शना । वेगळाच अनुभव आला । विषण्ण मन त्या क्षणा ॥ १८२ ॥
दोघे शेगावी परतले । गावकर्‍यांस सारे कळले । परी कुणी खरे न मानले । मायेने सर्वांना चकवले ॥ १८३ ॥
हरी म्हणे नाव पैलतीरी जाणार । पुन्हा न आपणा दिसणार । आठवणीच रहाणार । साकार निराकार होणार ॥ १८४ ॥
श्रावण महिना आला । देहभोग निमित्त झाला । तोच कारण बनला । पुढचा प्रसंग बोलका केला ॥ १८५ ॥
पुढे भाद्रपद आला । गणेश चतुर्थी दिवस आला । समर्थे बाळाभाऊचा हात धरला । आपल्या आसनावर बसवला ॥ १८६ ॥
विठ्ठलाचे होते सहाय्य । तोवरी होता देह । संपले आमचे कार्य । उद्यापासून तुझे कार्य ॥ १८७ ॥
शके अठराशे बत्तीस । साधारण नामे संवत्सरास । गुरूवारी ऋषीपंचमीस । सकाळी आठच्या सुमारास ॥ १८८ ॥
आठ सप्टेंबर एकोणीसशे दहास । संपवले अवतार कार्यास । रहाता विठ्ठलाच्या आज्ञेस । गेला पुढील कार्यास ॥ १८९ ॥
तोच समाधी दिवस ठरला । सगळ्यांना मागे ठेवता झाला । आपण आज्ञेने पुढे गेला । अनंतात विलीन झाला ॥ १९० ॥
वागला ऐशा कृतीस । दाखवले चमत्कृतीस । उपयोगी सर्वांस । त्यासाठीच अवतारास ॥ १९१ ॥
आधार ठरला सर्व भक्तांस । सर्वांना लावले मार्गास । कैसा विसरणार त्यास ? । विसरण्याने होईल र्‍हास ॥ १९२ ॥
महाराज देहाने गेले । हे सर्वांनाच कळले । पहाता पहाता सर्व जमले । अगदी मनापासून आलेले ॥ १९३ ॥
काही भक्तांच्या स्वप्नात गेले । पुढिल मुक्कामाचे वदले । भक्त शोकाकूल झाले । व्यथीत मने शेगावी आले ॥ १९४ ॥
झुंडीच्या झुंडी उमटल्या । अंत्यदर्शनास लोटल्या । जो तो शेगावी आला । सारा परीवार उदासीन झाला ॥ १९५ ॥
म्हणती समर्थ बोलके व्हावेत । योगलीलेचे चमत्कार व्हावेत । मनोरथ पूर्ण करावेत । कृपाशिष मिळावेत ॥ १९६ ॥
डोणगावच्या गोविंदशास्त्र्यांस । हा विचार न पटला त्यांस । ते म्हणाले भक्तगणांस । नका विनवू ऐसे त्यांस ॥ १९७ ॥
विनवता समर्थ उठतील । भक्तांस दर्शन देतील । निश्चित बोलके होतील । परी ईश्वरास न रुचेल ॥ १९८ ॥
ईश्वरास रुचेल तेच करावे । आपलेच म्हणणे खरे न करावे । समर्थांस न त्रास द्यावे । स्वस्थ त्यांस पडू द्यावे ॥ १९९ ॥
सारी भक्त मंडळी हळहळली । पालखीची तयारी केली । इकडे भजने चाललेली । नामगजराची सोबत झाली ॥ २०० ॥
प्रहर नि प्रहर स्वतःहून । गेले भजनात रंगून । मंत्रोच्चार नि गजर करून । महाराजमय होऊन ॥ २०१ ॥
साथ होती वेळेची । वाट न पहावी लागे तिची । गजानन गजानन म्हणायची । देहभान विसरायची ॥ २०२ ॥
स्त्रीया, मुले, वृद्ध । तेथे जमले भक्तवृंद । जो तो दर्शनास सिद्ध । हाच हेतु मनी शुद्ध ॥ २०३ ॥
पहाते झाले महाराजांस । पुन्हा न दिसणार आपणास । हाच हेतू त्यांच्या मनास । जेणे आले दर्शनास ॥ २०४ ॥
पालखी तयार झाली । फुलहारांनी सजवली । अंतीमविधीची सुरुवात झाली । मंत्रोच्चाराची सुरुवात झाली ॥ २०५ ॥
महाराजांस विधियुक्त स्नान । घातले सर्वांगावरून । अबीर गुलाल उधळून । वस्त्रालंकार सजवून ॥ २०६ ॥
महाराजांना पालखीत ठेवले । फुलहार अर्पण केले । भक्तांनी अखेरचे दर्शन घेतले । कोंडलेले अश्रू मोकळे झाले ॥ २०७ ॥
गावात पालखी फिरवली । अबालवृद्ध सारी जमली । “जय गजानन” न्हणू लागली । समर्थांना नमस्कार करू कागली ॥ २०८ ॥
सर्व भक्त पोरके झाले । सजीव निर्जीव हळहळले । वातावरण गदगदले । वेगळेच जाणवू लागले ॥ २०९ ॥
भजनाचा रंग वेगळा । अश्रुंची साथ मागता झाला । बोलका अबोल झाला । अबोल बोलका झाला ॥ २१० ॥
कुटाळांच्याही हातात । टाळ होते दिसत । झांजांच्या तालात । भजने होती गात ॥ २११ ॥
ब्राह्मणांचे मंत्रोच्चारण । त्यात समाधी प्रयोजन । सर्वांना अखेरचे दर्शन । एक वेगळेच वातावरण ॥ २१२ ॥
अबीर, बुक्का, गुलाल । उधळत होते वृद्ध अबाल । स्त्रीया, मुलेही सामील । कोण त्यांना सोडील ? ॥ २१३ ॥
वृत्तीत भाव होते कृतज्ञ । व्यक्त करत होते ते जन । कनवाळू कृपाळू गजानन । जगले स्वतःस विसरून ॥ २१४ ॥
रात्रभर पालखी फिरवली । मंत्रगजराने साथ दिली । दुसरे दिवशी सकाळी । पालखी मठात आली ॥ २१५ ॥
समाधीच्या जागेवर । समर्थांस ठेवले त्यावर । केला रुद्राभिषेक त्यावर । पूजा पंचोपचार ॥ २१६ ॥
पंचारती झाल्यावर । मीठ, अबीर, अर्गजा समाधीवर । भक्तांनी केला नामगजर । शिळा ठेवली त्यावर ॥ २१७ ॥
अनंताकडून तेज आलेले । अनंताकडे ते गेले । दहा दिवस समाराधना चालले । कित्येक भक्तांनी प्रसाद घेतले ॥ २१८ ॥
महाराज समाधीस्त झाले । भक्तगण हळहळले । नियतीपुढे काही न चाले । “जय गजानन, जय गजानन” म्हणू लागले ॥ २१९ ॥
भक्तांच्या अंतर्मनास । गजानन सांगे त्यांस । स्मरण करता आम्हांस । दर्शन देईल तुम्हांस ॥ २२० ॥
भक्तांसाठी अवतरणारा । भक्तांसाठीच जगणारा । भक्तांतच रमणार । सदा जागृत ठेवणारा ॥ २२१ ॥
समाधीनंतरही बोलणारा । सर्वांचे हित चिंतणारा । सदा सहाय्यभूत होणारा । संत गजानन समर्थ खरा ॥ २२२ ॥
गणामहाराज सांगे भक्तांस । नका विसरू मंत्रजपास । गजाननाचा मंत्र स्मरल्यास । गजानन दिसेल तुम्हांस ॥ २२३ ॥
सच्चिदानंद गजानन । सदा रक्षिती भक्तगण । जे करिती मंत्रपठण । त्यांचे संकट हरण ॥ २२४ ॥
 
॥ शुभं भवतु ॥
॥ श्री गणपती सरस्वतीर्पणमस्तु ॥
॥ इति श्रीगुरूगजाननलीला ग्रंथस्य एकोनविंशोऽध्यायः संपूर्णः ॥
</p>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Generic
